<template>
    
<div>


<v-card
    class="mx-auto"
    max-width="500"
  >
    <v-toolbar
      color="teal"
      dark
    >

      <v-toolbar-title>Checkout</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn class="mr-2"    icon @click="$router.push('/market/cart')">
         View all 
      </v-btn>
    </v-toolbar>

    <v-list>
      <v-list-group
        v-for="(items, index) in item_list"
        :key="index"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="display-2" v-text="items[0].supplier_name"></v-list-item-title>
            <v-row>
              <v-col cols="4">
                <div style="margin-bottom: 10px;">{{items.length}} items</div>
                <div>{{subTotal(items)+shippingCalc(items) | dollar_format}}</div>
              </v-col>
              <v-col cols="8">
                <div style="margin-bottom: 10px;">Credit limit available</div>
                <div v-if="credit_limit_arr[items[0].supplier] != undefined"> {{credit_limit_arr[items[0].supplier] | dollar_format}} </div>
              </v-col>
            </v-row>
          </v-list-item-content>
        </template>


        <v-list-item
          v-for="(item, idx) in items"
          :key="idx"
        >
          <v-list-item-content style="cursor: pointer;" @click="$router.push('/market/shop-detail/' + item.product_id)">
            <v-list-item-title>{{item.product_name}}<span v-if="item.item_name != 'default'">({{item.item_name}})</span></v-list-item-title>
            <v-list-item-title>price: {{item.price | dollar_format}}</v-list-item-title>
            <v-list-item-title>qty: {{item.qty}}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon @click="removeItem(item.id)">mdi-close</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item style="padding-left: 30px;">
          <v-list-item-content>
            <v-list-item-title style="font-size: 15px;">Subtotal: {{ subTotal(items) | dollar_format }}</v-list-item-title>
            <v-list-item-title style="font-size: 15px;">Shipping: {{ shippingCalc(items) | dollar_format }}</v-list-item-title>
            <v-list-item-title style="font-size: 15px;">Total: {{ subTotal(items)+shippingCalc(items) | dollar_format }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
      <v-card-actions
      class="ml-20">
              Total Shipping Cost: {{checkout_shiptotal | dollar_format}} <br/>
              Total: {{checkout_total + checkout_shiptotal | dollar_format}}
            </v-card-actions>
  </v-card>












     

          <nav-tabs-card primary>
            
            <template slot="content">
              <v-form v-model="orderformvalid" @submit.prevent="placeOrderShow">
                <md-tabs class="md-primary" md-alignment="left">
                  <md-tab id="tab-home" md-label="Bill To" md-icon="face">
                    <v-row align="center">
                      <v-col class="d-flex" cols="12" sm="12">
                        <v-select
                          v-model="billto"
                          :items="billtoAddress"
                          label="Default Bill To"
                          item-value="id"
                          @change="billtoChange"
                          :rules="[rules.required]"
                        >
                          <template slot="selection" slot-scope="{ item }">
                            <p>{{item.address_line1}}, {{item.address_line2}}, {{item.city}} {{item.state}} {{item.zip_code}}</p>
                          </template>
                          <template slot="item" slot-scope="{ item }">
                            <p>{{item.address_line1}}, {{item.address_line2}}, {{item.city}} {{item.state}} {{item.zip_code}}</p>
                          </template>
                        </v-select>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="12">
                        <v-checkbox
                          label="Custom address"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </md-tab>

                  <md-tab id="tab-pages" md-label="Ship To" md-icon="chat">
                    <v-row align="center">
                      <v-col class="d-flex" cols="12" sm="12">
                        <v-text-field
                          label="Shipping Method"
                          placeholder="Standard shipping"
                          disabled
                        >
                        </v-text-field>
                          
                        <!-- <v-select
                          :items="items"
                          label="Shipping Method"
                        ></v-select> -->
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="12">
                        <v-select
                          v-model="shipto"
                          :items="shiptoAddress"
                          label="Default Ship To"
                          item-value="id"
                          @change="shiptoChange"
                          :rules="[rules.required]"
                        >
                          <template slot="selection" slot-scope="{ item }">
                            <p>{{item.address_line1}}, {{item.address_line2}}, {{item.city}} {{item.state}} {{item.zip_code}}</p>
                          </template>
                          <template slot="item" slot-scope="{ item }">
                            <p>{{item.address_line1}}, {{item.address_line2}}, {{item.city}} {{item.state}} {{item.zip_code}}</p>
                          </template>
                        </v-select>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="12">
                        <v-checkbox
                          label="Custom address"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </md-tab>

                  <md-tab id="tab-posts" md-label="Payment" md-icon="money">
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="12">
                        <v-text-field
                          label="PO NUMBER"
                          v-model="po_number"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="12">
                        <v-checkbox
                          hide-details
                          class="shrink mr-2 mt-0"
                        ></v-checkbox>
                        <v-select :items="items" label=" Card"></v-select>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="12">
                        <v-checkbox
                          hide-details
                          class="shrink mr-2 mt-0"
                          label="Net Terms"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </md-tab>
                </md-tabs>

                <v-btn
                  :disabled="!orderformvalid"
                  type="submit"
                  color="blue darken-1"
                >
                  Place Order
                </v-btn>
    
              </v-form>
            </template>
          </nav-tabs-card>
        

<v-dialog
      v-model="orderDialog"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="orderDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Please Review Order and hit Place Order</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="orderDialog = false"
            >
              Place Order
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      
<div class="container">
  <div v-for="(items, index) in item_list" :key="index">
    <div class="display-2">  {{items[0].supplier_name}} </div>
    <table class="table  table-checkable" id="kt_datatable" style="margin-top: 13px !important">
      <thead>
        <tr>                          
          <th>Line ID</th>
          <th>SKU</th>
          <th>Thumb</th>
          <th>Item</th>
          <th>Qty</th>
          <th>Unit</th>
          <th>Cost</th>
          <th>Extended Cost</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, idx) in items" :key="idx">
          <td>{{idx+1}}</td>
          <td>{{item.sku_id}}</td>
          <td> <v-img class="d-flex align-self-center mr-3 rounded-circle" :src="item.url != null ? baseurl + item.url : '/media/rollerskate.png'" alt="Generic placeholder image"  width="50" height="50"> </v-img></td>
          <td>{{item.product_name}}
            <span v-if="item.item_name != 'default'">({{item.item_name}})</span>
          </td>
          <td>{{item.qty}}</td>
          <td>each</td>
          <td>{{item.price | dollar_format}}</td>
          <td>{{item.price * item.qty | dollar_format}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>Subtotal</td>
          <td>{{subTotal(items) | dollar_format}}</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td colspan="6" style="border:0;">
          <td style="border:0;">Shipping Total</td>
          <td style="border:0;">{{shippingCalc(items) | dollar_format}}</td>
        </tr>
        <tr>
          <td colspan="6" style="border:0;">
          <td style="border:0;">Final vendor Total</td>
          <td style="border:0;">{{subTotal(items)+shippingCalc(items) | dollar_format}}</td>
        </tr>
      </tbody>
    </table>
  </div>

    <div class="row">
      <div class="col-lg-9">
      </div>
      <div class="col-lg-3">
        <div class="display-2"> Total  {{ checkout_total+checkout_shiptotal | dollar_format }}  </div>
      </div>
    </div>

                                                                              

                                        <div class="row ">
                            <div class="col-lg-4">
                                <v-card class="card card-custom card-stretch">
                                    
                                    <div class="card-header">
                                        <div class="card-title">
													<h3 class="card-label">Billing Info</h3>
								        </div> 
                                    </div>
                                    <div class="card-body" v-if="billto != 0 && (billto in billtoAddressObj)">

                                        <h5 class="font-family-primary font-weight-semibold">{{billtoAddressObj[billto].company_name}}</h5>
                                        
                                        <p class="mb-2">
                                          <span class="font-weight-semibold mr-2">Address:</span>
                                          {{billtoAddressObj[billto].address_line1}} <br/>
                                          {{billtoAddressObj[billto].address_line2}} <br/>
                                          {{billtoAddressObj[billto].city}} {{billtoAddressObj[billto].state}} {{billtoAddressObj[billto].zip_code}}
                                        </p>
            
                                    </div>
                                </v-card>
                            </div> <!-- end col -->
                         <div class="col-lg-4">
                                <v-card class="card card-custom card-stretch">
                                    
                                    <div class="card-header">
                                        <div class="card-title">
													<h3 class="card-label">Shipping Info</h3>
								        </div>
                                    </div>
                                    <div class="card-body" v-if="shipto != 0 && (shipto in shiptoAddressObj)">

                                        <h5 class="font-family-primary font-weight-semibold">{{shiptoAddressObj[shipto].company_name}}</h5>
                                        
                                        <p class="mb-2">
                                          <span class="font-weight-semibold mr-2">Address:</span>
                                          {{shiptoAddressObj[shipto].address_line1}} <br/>
                                          {{shiptoAddressObj[shipto].address_line2}} <br/>
                                          {{shiptoAddressObj[shipto].city}} {{shiptoAddressObj[shipto].state}} {{shiptoAddressObj[shipto].zip_code}}
                                        </p>
            
                                    </div>
                                </v-card>
                            </div> <!-- end col -->
    </div>
</div>
        <v-divider></v-divider>
        <v-list
          subheader
        >
          <v-subheader>
            <v-btn success @click="$router.push('/market/cart')"> Edit Order </v-btn>
            <v-btn success @click="placeOrder"> Place Order </v-btn>
          </v-subheader>
        </v-list>
      </v-card>
    </v-dialog>
</div>

</template>


<script>
import NavTabsCard from "./base/cards/NavTabsCard.vue";
import FullBgCard from "./base/cards/FullBgCard.vue";

import Swal from "sweetalert2";
import { mapGetters, mapActions } from "vuex";
import gql from "graphql-tag";


export default {
  components: {
     NavTabsCard,
  },
  data () {
    return {
      credit_limit_arr: {},
      po_number: '',
      baseurl: process.env.VUE_APP_STRAPI_API_URL || 'http://localhost:1337',
      orderDialog: false,
      orderformvalid: true,
      rules: {
        required: (value) => !!value || "Required."
      },
      billto: 0,
      shipto: 0,
      billtoAddress: [],
      billtoAddressObj: {},
      shiptoAddress: [],
      shiptoAddressObj: {},
      item_list: [],
      checkout_total: 0,
      checkout_shiptotal: 0,
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
items: [
        {
          items: [{ title: 'Line Item 1 and Price' }],
          title: 'Best Bike Supplier',
          qty: '6 items' ,
          total: '$4500.50',
        },
        {
          items: [
            { title: 'Line Item 1 and Price' },
            { title: 'Line Item 2 and Price' },
            { title: 'Line Item 3 and Price' },
          ],
          title: 'Outdoor Fashion',
           qty: '5 items' ,
          total: '$2300.50',
        },
        {
          items: [{ title: 'List Item' }],
          title: 'Discount Shoes',
           qty: '36 items' ,
          total: '$45000.50',
        },
       
      ],
       cardProject: {
        cardProject1: require("@/assets/img/examples/office2.jpg"),
        cardProject2: require("@/assets/img/examples/card-blog3.jpg"),
        cardProject3: require("@/assets/img/examples/card-project6.jpg")
      },

        currentPage: 1,
        partnerCount: 0,
        limit: 12,
        api_url: process.env.VUE_APP_STRAPI_API_URL,
        partnerList: []
    }
  },

  apollo: {
    carts: {
      query: gql`
        query CartsQuery {
          carts {
            id
            supplier
            price
            qty
            item_id
            product_id
            product_name
            item_name
            sku_id
            supplier_name
            is_checkout
            url
          }
        }
      `,
      client: 'ecomClient',
      skip() {
        return this.$store.state.auth.user.company_relation == null
      }
    },
    seller_cart_address: {
      query: gql`
        query SellerCartAddress {
          seller_cart_address {
            id
            cart_id
            strapi_id
            address_line1
            address_line2
            city
            state
            zip_code
            address_type
          }
        }
      `,
      client: 'ecomClient',
      skip() {
        return this.$store.state.auth.user.company_relation == null
      }
    },
    credit_limit: {
      query: gql`
        query CreditLimit {
          credit_limit {
            id
            seller_id
            supplier_id
            credit_available
          }
        }
      `,
      client: 'ecomClient',
      skip() {
        return this.$store.state.auth.user.company_relation == null
      }
    }
  },
  filters: {
    dollar_format: function (value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
      return formatter.format(value);
    }
  },
  created () {
    setTimeout(() => {
      this.updateAddressList();
      this.getCarts();
    }, 1000);
  },
  methods: {
    ...mapActions([
      'getCartWhere',
      'getCompanyInfo',
      "getAddressWhere"
    ]),
    async updateCartAddress() {
      let res = await this.$apollo.mutate({
        client: "ecomClient",
        mutation: gql`
          mutation updateCartAddressMutation(
            $billto: Int!
            $shipto: Int!
          ) {
            updateCartAddress(
              billto: $billto
              shipto: $shipto
            )
          }
        `,
        variables: {
          billto: parseInt(this.billto),
          shipto: parseInt(this.shipto)
        }
      });
    },
    billtoChange() {
      this.updateCartAddress();
    },
    shiptoChange() {
      this.updateCartAddress();
    },
    async placeOrder() {
      let res = await this.$apollo.mutate({
        client: "ecomClient",
        mutation: gql`
          mutation AddOrderMutation($po_number: String!) {
            addOrder(
              po_number: $po_number
            )
          }
        `,
        variables: {
          po_number: this.po_number
        },
      });
      this.po_number = '';
      if(res.data.addOrder == true) {
        this.orderDialog = false;
        Swal.fire({
          title: "",
          text: "Placed order.",
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        });
        this.getCarts();
      }
      else {
        this.orderDialog = false;
        Swal.fire({
          title: "",
          text: "Error Occured.",
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        });
      }
    },
    placeOrderShow() {
      if(this.item_list.length > 0) {
        let flag = 0;
        for(let i in this.item_list) {
          if(this.item_list[i].length > 0) {
            if (this.credit_limit_arr[this.item_list[i][0].supplier] != undefined) {
              let subtotal = this.subTotal(this.item_list[i]) + this.shippingCalc(this.item_list[i]);
              console.log(subtotal);
              if(parseFloat(subtotal) > parseFloat(this.credit_limit_arr[this.item_list[i][0].supplier])) {
                flag = 1;
              }
            }
          }
        }
        if(flag == 1) {
          Swal.fire({
            title: "",
            text: "Not enough credit.",
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });
        } else {
          this.orderDialog = true;
        }
      } else {
        Swal.fire({
          title: "",
          text: "Cart is empty.",
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
      }
    },
    shippingCalc(items) {
      let sum = 0;
      if(items.length > 0) {
        if(items[0].shipping_fixed_rate != null && items[0].shipping_fee_type != null) {
          if(items[0].shipping_fee_type == 'item') {
            for(let i in items) {
              sum += items[i].qty * items[0].shipping_fixed_rate;
            }
          } else {
            sum = items[0].shipping_fixed_rate;
          }
        }
      }

      return sum;
    },
    updateAddressList() {
      this.billtoAddress = [];
      this.shiptoAddress = [];
      this.billtoAddressObj = {};
      this.shiptoAddressObj = {};
      this.getAddressWhere({
        where: "?_where[0][seller_company]="+ this.$store.state.auth.user.company_relation
      })
      .then((res) => {
        for(let i in res.data) {
          if(res.data[i].type == 'billto') {
            this.billtoAddress.push(res.data[i]);
            this.billtoAddressObj[res.data[i].id] = res.data[i];
          } else {
            this.shiptoAddress.push(res.data[i]);
            this.shiptoAddressObj[res.data[i].id] = res.data[i];
          }
        }
        this.getCartAddress();
      });
    },
    async getCartAddress() {
      await this.$apollo.queries.seller_cart_address.refetch();
      let address_arr = this.seller_cart_address;
      for(let i in address_arr) {
        if(address_arr[i].address_type == 'billto') {
          this.billto = address_arr[i].strapi_id;
        } else if(address_arr[i].address_type == 'shipto') {
          this.shipto = address_arr[i].strapi_id;
        }
      }
      if(this.billto == 0 || this.shipto == 0) {
        let cres = await this.getCompanyInfo({
          id: this.$store.state.auth.user.company_relation
        });
        if(cres.data.default_billto != null && this.billto == 0) {
          this.billto = cres.data.default_billto.id;
        }
        if(cres.data.default_shipto != null && this.shipto == 0) {
          this.shipto = cres.data.default_shipto.id;
        }
        this.updateCartAddress();
      }
    },
    async removeItem(id) {
      try {
        let res = await this.$apollo.mutate({
          client: 'ecomClient',
          mutation: gql`
              mutation RemoveMultipleCartsMutation($cart_ids: String!) {
                removeMultipleCarts(
                  cart_ids: $cart_ids
                )
              }
            `
          ,
          variables: {
            cart_ids: id.toString()
          },
        });
        if(res.data.removeMultipleCarts == true) {
          Swal.fire({
            title: "",
            text: "Items removed.",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
          this.getCarts();
        }
      }
      catch {
        Swal.fire({
          title: "",
          text: "An Error Occured",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      }
    },
    subTotal(items) {
      let sum = 0;
      if(items.length > 0) {
        for(let i in items) {
          sum += items[i].qty * items[i].price;
        }
      }
      return sum;
    },
    async getCarts() {
      await this.$apollo.queries.credit_limit.refetch();
      if(this.credit_limit != undefined && this.credit_limit.length > 0) {
        this.credit_limit_arr = {};
        for(let i in this.credit_limit) {
          this.credit_limit_arr[this.credit_limit[i].supplier_id] = parseFloat(this.credit_limit[i].credit_available);
        }
      }

      await this.$apollo.queries.carts.refetch();
      if(this.carts != undefined && this.carts.length > 0) {
        let item_array = {};
        for(let i in this.carts) {
          if(item_array[this.carts[i].supplier] == undefined) {
            item_array[this.carts[i].supplier] = [];
          }
          item_array[this.carts[i].supplier].push(this.carts[i]);
        }

        for(let i in item_array) {
          if(item_array[i] != null && item_array[i] != undefined) {
            let comres = await this.getCompanyInfo({
              id: i
            });
            if(item_array[i].length > 0) {
              item_array[i][0]['order_handling_time'] = comres.data.order_handling_time;
              item_array[i][0]['shipping_fixed_rate'] = comres.data.shipping_fixed_rate;
              item_array[i][0]['shipping_fee_type'] = comres.data.shipping_fee_type;
            }
          }
        }

        let t_items = [], ct_items = [], c_index = 0, ct_index = 0, ch_total = 0, ch_shiptotal = 0;
        for(let i in item_array) {
          if(item_array[i] != null && item_array[i] != undefined)
          {
            if(item_array[i].length > 0) {
              if(item_array[i][0].is_checkout == 1) {
                t_items.push(item_array[i]);
                //ship total
                if(item_array[i][0].shipping_fixed_rate != null && item_array[i][0].shipping_fee_type != null) {
                  if(item_array[i][0].shipping_fee_type == 'item') {
                    for(let j in item_array[i]) {
                      ch_shiptotal += item_array[i][j].qty * item_array[i][0].shipping_fixed_rate;
                    }
                  } else {
                    ch_shiptotal = item_array[i][0].shipping_fixed_rate;
                  }
                }

                for(let j in item_array[i]) {
                  ch_total += item_array[i][j].qty * item_array[i][j].price;
                }
              }
            }
          }
        }
        this.item_list = t_items;
        this.checkout_total = ch_total;
        this.checkout_shiptotal = ch_shiptotal;
      } else {
        this.item_list = [];
        this.checkout_total = 0;
        this.checkout_shiptotal = 0;
      }
    }
  }
}
</script>
